.design {
  .block {
    position: relative;
    width: 100%;
    height: clamp(200px, 40vw, 400px);
    background: rgb(0, 0, 0);
    margin: 5rem 0;
    color: white;
    &.logo {
      display: grid;
      place-items: center;
      svg {
        width: 10%;
        stroke-linecap: round;
        stroke-linejoin: round;
      }
    }
    &.color {
      height: clamp(150px, 40vw, 350px);
      display: flex;
      margin-bottom: 8rem;
      .color-space {
        color: white;
        margin: clamp(1rem, 4vw, 3rem) clamp(1rem, 4vw, 4rem);
        @media (min-width: $tablet-breakpoint) {
          margin-right: 0rem;
        }
        .color-channel {
          display: flex;
          align-items: center;
          margin-bottom: clamp(0.1rem, 1vw, 0.7rem);
          h3 {
            margin-right: clamp(0.5rem, 2vw, 1.5rem);
            color: white;
          }
          p {
            font-weight: 400;
            margin-left: auto;
            color: white;
          }
        }
        &:nth-of-type(1),
        &:nth-of-type(4) {
          h3 {
            margin-bottom: 0.5rem;
            color: white;
          }
        }
        h3 {
          font-size: clamp(0.8rem, 4vw, 1.4rem);
          color: white;
        }
        p {
          font-size: clamp(0.5rem, 4vw, 1rem);
          color: white;
          opacity: 0.8;
        }
      }
      .color-swatches {
        position: absolute;
        top: 100%;
        left: 0;
        height: 50px;
        height: clamp(20px, 10vw, 50px);
        width: 100%;
        display: flex;
        .color-swatch {
          height: 100%;
          width: 20%;
          @for $i from 1 through 5 {
            &:nth-of-type(#{$i}) {
              opacity: (1 - $i * 0.2);
            }
          }
        }
      }
    }
    &.font {
      padding: clamp(1rem, 4vw, 3rem) clamp(1rem, 4vw, 4rem);
      h2 {
        font-size: clamp(1rem, 3vw, 1.6rem);
        @media (max-width: $phone-breakpoint) {
          line-height: 1.7;
        }
      }
      h3 {
        font-size: clamp(2rem, 4vw, 5rem);
        letter-spacing: clamp(0.1rem, 1vw, 0.5rem);
        @media (max-width: $phone-breakpoint) {
          line-height: 1.2;
        }
      }
      p {
        font-size: clamp(0.9rem, 2vw, 1.2rem);
        letter-spacing: clamp(0.1rem, 0.2vw, 0.3rem);
        margin: clamp(0.1rem, 1vw, 0.7rem) 0;
        @media (max-width: $phone-breakpoint) {
          line-height: 1.2;
        }
      }
    }
    &.white {
      background: #ffffff;
      border: 2px solid #0000005d;
      color: black !important;
    }
  }
}
