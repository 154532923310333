$primary-color: rgb(10, 30, 65);
$secondary-color: rgb(100, 200, 255);
$tertiary-color: rgb(233, 233, 233);
$diff-color: rgb(calc(255 - 10), calc(255 - 30), calc(255 - 65));

$primary-font: "Lato", sans-serif;
$secondary-font: "Roboto", sans-serif;
$tertiary-font: "Victor Mono";

$tablet-breakpoint: 800px;
$phone-breakpoint: 450px;

:root {
  --denominator: 3;
}
