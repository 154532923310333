@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap");
@import "./fonts.scss";
@import "./variables.scss";
@import "../styles/components/Cursor.scss";
@import "../styles/components/Header.scss";
@import "../styles/components/Card.scss";
@import "../styles/components/IntroOverlay.scss";
@import "../styles/components/Design.scss";
@import "../styles/components/Footer.scss";
@import "../styles/components/Loader.scss";
@import "../styles/components/staggeredImages.scss";
@import "../styles/pages/Home.scss";
@import "../styles/pages/Information.scss";
@import "../styles/pages/Project.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: none;
}

html,
body {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  // @media (max-width: $phone-breakpoint) {
  // overflow: hidden;
  // }
  &.no-scroll {
    // overflow: hidden;
    position: fixed;
    overflow-y: scroll;
  }
}
// body {
//! prevent url from disappearing on mobile
// @media (max-width: $phone-breakpoint) {
//   width: 100%;
//   height: 100%;
//   position: fixed;
//   overflow-y: auto;
//   -webkit-overflow-scrolling: touch;
// }
// }

h2,
h3,
h4,
h5 {
  font-family: $primary-font;
}

p {
  font-family: $secondary-font;
}

.text-container {
  position: relative;
  width: 95%;
  margin: 0 auto;
  h2 {
    font-size: 1rem;
    color: $primary-color;
    margin-bottom: 0.5rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    font-size: 1.2rem;
  }
  p {
    font-family: $secondary-font;
    line-height: 1.7;
    letter-spacing: 0.05rem;
    color: rgb(130, 130, 130);
    font-size: 1.2rem;
  }
}

img {
  pointer-events: none;
  object-fit: cover;
}

a,
a:visited,
a:focus,
a:hover {
  color: $primary-color;
  text-decoration: none;
}

.container {
  position: relative;
  width: clamp(300px, 90vw, 1000px);
  margin: 0 auto;
  height: 100%;
  .row {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .v-center {
    align-items: center;
  }
  .space-between {
    justify-content: space-between;
  }
}

@media (prefers-reduced-motion: reduce) {
  *,
  ::before,
  ::after {
    animation-delay: -1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
    background-attachment: initial !important;
    scroll-behavior: auto !important;
    transition-duration: 0s !important;
    transition-delay: 0s !important;
  }
}

button {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
}

button:hover {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
}

button:active {
  outline: none;
  border: none;
}

button:focus {
  outline: 0;
}

.square-border {
  position: absolute;
  border: clamp(5px, 3vw, 20px) solid $primary-color;
  border-width: 16px;
  @media (max-width: $tablet-breakpoint) {
    border-width: 12px;
  }
  @media (max-width: $phone-breakpoint) {
    border-width: 7px;
  }
  &.basic {
    height: 95%;
    width: 70%;
    pointer-events: none;
    @media (max-width: $phone-breakpoint) {
      height: 100%;
      width: 80%;
    }
  }
  &.project-square-border {
    height: 87%;
    width: 30%;
    @media (max-width: $tablet-breakpoint) {
      top: 17%;
      height: 80%;
      width: 60%;
    }
    @media (max-width: $phone-breakpoint) {
      width: 80%;
    }
    .project-text-container {
      color: $primary-color;
      position: absolute;
      height: clamp(3rem, 4.5vw, 7rem);
      display: flex;
      overflow: hidden;
      margin: clamp(1rem, 2vw, 2rem);
      &:last-of-type {
        right: 0;
        bottom: 0;
      }
      &:first-of-type {
        left: 0;
        top: 0;
        width: 100%;
      }
    }
  }
  .square-border-accent {
    position: absolute;
    bottom: 0;
    height: clamp(50px, 5vw, 100px);
    width: clamp(50px, 5vw, 100px);
    border-left: clamp(2px, 2vw, 10px) solid $primary-color;
    border-bottom: clamp(2px, 2vw, 10px) solid $primary-color;
    margin: 1rem;
    &:nth-of-type(2) {
      top: 0;
      right: 0;
      height: clamp(40px, 4vw, 80px);
      width: clamp(40px, 4vw, 80px);
      transform: rotate(180deg);
    }
  }
}

.stroke-text {
  // color: $primary-color;
  // -webkit-text-fill-color: rgba(255, 255, 255, 0);
  -webkit-text-stroke-width: 5px;
  -webkit-text-stroke-color: $primary-color;
}
