footer {
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  contain: layout paint;
  margin-top: 5rem;
  .footer-content {
    position: absolute;
    top: 50%;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .footer-text {
      position: absolute;
      padding: 2rem 0;
      color: $primary-color;
      width: 90%;
      top: -10%;
      text-align: center;
      h2 {
        font-size: clamp(2rem, 6vw, 8rem);
        line-height: clamp(2rem, 5vw, 7rem);
        text-transform: uppercase;
      }
    }
    .footer-square-border {
      position: absolute;
      height: 87%;
      width: 90%;
      border: clamp(5px, 3vw, 20px) solid $primary-color;
      @media (max-width: $tablet-breakpoint) {
        top: 10%;
      }
    }
    .footer-image {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 1;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
