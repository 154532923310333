.fullscreen {
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(255, 255, 255, 0);
  z-index: 5;
  .side-strip {
    position: absolute;
    background: $primary-color;
    width: 100%;
    &:nth-of-type(1) {
      top: 0;
      left: 0;
    }
    &:nth-of-type(2) {
      bottom: 0;
      right: 0;
      height: 100%;
    }
    &:nth-of-type(3) {
      bottom: 0;
      left: 0;
    }
    &:nth-of-type(4) {
      bottom: 0;
      left: 0;
      height: 100%;
    }
  }
  .inner-strip {
    position: absolute;
    background: $primary-color;
  }
  .inner-square {
    position: absolute;
    background: $primary-color;
  }
  .overlay-logo-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 750px;
    fill: $primary-color;
    @media (max-width: $tablet-breakpoint) {
      height: 400px;
    }
    @media (max-width: $phone-breakpoint) {
      height: 200px;
    }
    svg {
      height: 100%;
    }
  }
}
