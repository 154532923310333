.staggered-images {
  position: relative;
  width: 100%;
  display: flex;
  .artwork-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    &.right {
      margin-top: 30%;
      margin-left: 2rem;
      @media (max-width: $phone-breakpoint) {
        margin-left: 1rem;
      }
    }
    img {
      position: relative;
      width: 100%;
    }
  }
}
