.example-container {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: grid;
  place-items: center;
  .controls {
    position: absolute;
    bottom: 0;
    height: 75px;
    width: 40%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media (max-width: $tablet-breakpoint) {
      width: 90%;
    }

    .next,
    .prev {
      z-index: 2;
      width: 40px;
      stroke: $primary-color;
      stroke-width: 2;
      stroke-linecap: round;
      stroke-linejoin: round;
      @media (min-width: $phone-breakpoint) {
        width: 60px;
      }
      &.prev {
        transform: scale(-1);
      }
    }
    .progress-wrapper {
      display: flex;
      align-items: baseline;
      width: 30%;
      justify-content: center;
      .dot {
        position: relative;
        height: 10px;
        width: 10px;
        margin: 0 0.5rem;
        border-radius: 50px;
        border: 2px solid $primary-color;
        transition: all 0.3s ease-in-out;
        opacity: 0.5;
        &.active {
          opacity: 1;
          background: $primary-color;
          transform: scale(1.2);
        }
      }
      .number {
        font-family: $primary-font;
        color: $primary-color;
        font-size: 2rem;
        opacity: 0.3;
        &.current {
          opacity: 1;
          font-size: 10rem;
          font-weight: 900;
        }
      }
    }
  }
  img {
    position: absolute;
    width: 40%;
    max-width: 100vw;
    pointer-events: all;
    z-index: 2;
    @media (max-width: $tablet-breakpoint) {
      width: 90%;
    }
  }
}
