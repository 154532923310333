.header-transition-overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: $primary-color;
  z-index: 6;
}
.header {
  position: fixed;
  width: 100%;
  height: 100px;
  z-index: 5;
  font-family: $primary-font;
  pointer-events: none;
  nav {
    left: 0;
    top: -1px;
    width: 100%;
    height: 0;
    position: fixed;
    z-index: 2;
    display: block;
    overflow: hidden;
    color: $primary-color;
    background: $secondary-color;
    a {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 10px;
        display: block;
        margin-top: 5px;
        top: 50%;
        right: 0;
        background: $primary-color;
        transition: 0.3s ease;
      }
      &:hover {
        &:after {
          width: 100%;
          left: 0;
        }
      }
    }
    .nav-links {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      height: 95%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      pointer-events: all;
      @media (max-width: $phone-breakpoint) {
        height: 90%;
      }
      li {
        list-style: none;
        a {
          font-weight: 600;
          text-decoration: none;
          font-size: clamp(2rem, 10vw, 8rem);
        }
      }
    }
  }

  .logo {
    position: relative;
    z-index: 5;
    font-size: 1.2rem;
    letter-spacing: 0.5rem;
    height: 45%;
    text-decoration: none;
    font-weight: 700;
    pointer-events: all;
    @media (max-width: $phone-breakpoint) {
      transform: scale(0.8);
    }
    svg {
      height: 100%;
      fill: $primary-color;
    }
    a {
      text-decoration: none;
      &:visited {
        color: inherit;
      }
    }
  }
  .nav-toggle {
    position: relative;
    z-index: 5;
    pointer-events: all;
    height: 30%;
    .hamburger-menu {
      position: relative;
      width: 35px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      @media (max-width: $phone-breakpoint) {
        transform: scale(0.9);
      }
      span {
        position: relative;
        background: $primary-color;
        height: 4px;
        width: 100%;
      }
    }
  }
  &.mix-blend {
    mix-blend-mode: difference;
    .logo {
      svg {
        fill: $diff-color;
      }
    }
    .nav-toggle {
      .hamburger-menu {
        span {
          background: $diff-color;
        }
      }
    }
  }
}
