@font-face {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../assets/fonts/JosefinSans-VariableFont_wght.ttf) format("truetype");
}
@font-face {
  font-family: "Vollkorn";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../assets/fonts/Vollkorn-VariableFont_wght.ttf) format("truetype");
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../assets/fonts/OpenSans-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Victor Mono";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../assets/fonts/VictorMono-Regular.ttf) format("truetype");
}
