.project-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: white;
  contain: layout paint;
  z-index: 0;
  .project-landing {
    position: relative;
    top: 0;
    left: 0;
    height: 100vh;
    // height: calc(var(--vh, 1vh) * 99.9);
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 100%;
      width: 100%;
    }
  }

  .project-overview {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    margin-top: 5rem;
    .block-wrapper {
      position: relative;
      width: 95%;
      margin: auto;
      .row {
        align-items: initial;
        justify-content: space-between;
        @media (max-width: $tablet-breakpoint) {
          flex-direction: column;
        }
        .text-block {
          position: relative;
          top: 0;
          width: 25%;
          @media (max-width: $tablet-breakpoint) {
            width: 100%;
            + .text-block {
              margin-top: 2rem;
            }
          }
          h2 {
            font-size: 1rem;
            color: $primary-color;
            margin-bottom: 0.5rem;
            letter-spacing: 0.1rem;
            text-transform: uppercase;
            font-size: 1.2rem;
          }
          p {
            line-height: 1.7;
            letter-spacing: 0.05rem;
            color: rgb(130, 130, 130);
            font-size: 1.2rem;
          }
        }
      }
    }
  }

  .project-copy {
    width: 100%;
    background: white;
    margin: 5rem 0;
  }

  .project-images {
    position: relative;
    width: 100%;
    background: $tertiary-color;
    padding-bottom: 5rem;
    z-index: 0;
    .squares-wrapper {
      width: 100%;
      margin: 0 auto;
      .row {
        flex-wrap: nowrap;
        justify-content: space-between;
        @media (max-width: $tablet-breakpoint) {
          flex-direction: column;
        }
        picture {
          flex-grow: 1;
          @media (min-width: $tablet-breakpoint) {
            &:first-of-type {
              margin-right: 5rem;
            }
          }
          @media (max-width: $tablet-breakpoint) {
            width: 100%;
          }
          img {
            width: 100%;
            margin: 0;
          }
        }
      }
    }
    img {
      width: 100%;
      object-fit: cover;
      display: block;
      margin: 0 auto;
      padding-top: 5rem;
    }
  }

  .project-responsive {
    position: relative;
    z-index: 0;
    img {
      margin-bottom: 5rem;
      width: 100%;
      filter: drop-shadow(0px 0px 1px rgb(175, 175, 175));
    }
  }
}

// ! new

// .project-wrapper {
//   position: relative;
//   height: 100%;
//   width: 100%;
//   z-index: 0;
//   .project-landing {
//     position: relative;
//     top: 0;
//     left: 0;
//     height: 100vh;
//     height: calc(var(--vh, 1vh) * 99.9);
//     width: 100%;
//     overflow: hidden;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     opacity: 0.3;
//     // contain: layout paint;
//     z-index: -1;
//     picture {
//       // position: absolute;
//       height: 100%;
//       width: 100%;
//       img {
//         height: 100%;
//         width: 100%;
//       }
//     }
//   }
// }
