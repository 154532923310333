.cursor {
  position: fixed;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  transform: translate3d(-50%, -50%, 0);
  pointer-events: none;
  transition: height 0.2s, width 0.2s, transform 0.2s, opacity 0.2s;
  mix-blend-mode: multiply;
  &:nth-child(1) {
    background: $primary-color;
    z-index: 100002;
    &.pointer {
      height: 10px;
      width: 10px;
    }
    &.hovered {
      height: 60px;
      width: 60px;
    }
    &.clicked {
      height: 10px;
      width: 10px;
    }
  }
  &:nth-child(2) {
    border: 2px solid $primary-color;
    z-index: 100001;
    height: 60px;
    width: 60px;
    &.pointer {
      height: 10px;
      width: 10px;
    }
    &.hovered {
      border: 0px solid $primary-color;
      height: 0;
      width: 0;
    }
    &.clicked {
      height: 70px;
      width: 70px;
    }
  }
}
