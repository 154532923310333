.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: $primary-color;
  z-index: 99999;
  pointer-events: none;
  display: grid;
  place-items: center;
  .icon-wrapper {
    position: absolute;
    width: 12%;
    fill: none;
    stroke: white;
    stroke-width: 2px;
    path {
      stroke: white !important;
      stroke-width: 2px !important;
    }
    @media (max-width: $phone-breakpoint) {
      width: 40%;
    }
  }
}
