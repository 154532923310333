.main-dark-background {
  position: relative;
  overflow-x: hidden;
  width: 100%;
  background: rgb(245, 245, 245);
  color: $primary-color;
  contain: layout paint;
  .about-landing {
    position: relative;
    height: 100vh;
    width: 100%;
    .info-overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: $primary-color;
      z-index: 3;
      opacity: 0;
    }
    .content {
      position: absolute;
      top: 55%;
      left: 50%;
      width: 60%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      @media (max-width: $tablet-breakpoint) {
        width: 90%;
      }
      .scroll-indicator {
        align-self: flex-end;
        margin-top: 3rem;
        opacity: 1;
        width: clamp(0px, 7vw, 50px);
        stroke: $primary-color;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        transform: rotate(90deg);

        // top: calc(50% - 20px);
        // position: absolute;
        border-radius: 50px;
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
        font-weight: bold;
        font-size: 18px;
        z-index: 2;
        width: 60px;
        stroke: $primary-color;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        align-self: flex-end;
      }
      p {
        color: rgb(125, 125, 125);
        margin-bottom: clamp(0.3rem, 1vw, 1rem);
        line-height: clamp(1, 2vw, 1.5);
        font-size: clamp(1rem, 4vw, 1.4rem);
      }
    }
  }
  .info-section {
    position: relative;
    padding-bottom: clamp(2rem, 10vw, 10rem);
    .parallax-images {
      position: relative;
      width: 100%;
      margin: clamp(1rem, 7vw, 7rem) 0;
      display: flex;
      padding: 1rem;
      padding-top: 0;
      .artwork-container {
        display: flex;
        flex-direction: column;
        width: 50%;
        margin: 1rem;
        @media (max-width: $phone-breakpoint) {
          margin: 0.3rem;
        }
        &.right {
          margin-top: 30%;
        }
        img {
          position: relative;
          width: 100%;
          margin: 1rem 0;
          @media (max-width: $phone-breakpoint) {
            margin: 0.3rem 0;
          }
          &:nth-of-type(odd) {
            margin-right: 1rem;
          }
        }
      }
    }
    svg {
      position: relative;
      left: 0;
      margin-top: clamp(1rem, 6vw, 5rem);
      width: 100%;
      fill: none;
      stroke: $primary-color;
      stroke-width: 5;
      stroke-linecap: round;
      stroke-linejoin: round;
      #California {
        stroke-width: 10;
      }
      #large-circle {
        stroke: none;
        fill: $secondary-color;
      }
      #small-circle {
        stroke: none;
        fill: $secondary-color;
        opacity: 1;
        transform: scale(1, 1);
        transform-origin: center center;
        transform-box: fill-box;
        animation: pulse 1.2s ease-in-out infinite;
      }
      text {
        font-family: "Lato-Black", "Lato", sans-serif;
        font-weight: 900;
        font-size: 1625px;
        fill: black;
        stroke: none;
        fill-opacity: 0.05;
      }
      @keyframes pulse {
        0% {
          transform: scale(1, 1);
          opacity: 1;
        }
        100% {
          transform: scale(3, 3);
          opacity: 0;
        }
      }
    }
    .services {
      overflow: hidden;
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 1rem 0;
      margin-bottom: 5rem;
      @media (max-width: $tablet-breakpoint) {
        margin-bottom: 2rem;
      }
      .service {
        position: relative;
        &.transitioning {
          transition: transform 0.2s, opacity 0.2s;
        }
        h2 {
          white-space: nowrap;
          text-transform: uppercase;
          font-size: clamp(1rem, 8vw, 13.5rem);
        }
      }
    }
    .questions {
      position: relative;
      width: 100%;
      margin: 2rem 0;
      margin: clamp(1rem, 2vw, 2rem) 0;
      .question {
        background: none;
        padding: 1rem 0.5rem;
        border-bottom: 1px $primary-color solid;
        display: flex;
        justify-content: space-between;
        align-items: center;
        svg {
          opacity: 1;
          stroke: $primary-color;
          stroke-width: 2;
          stroke-linecap: round;
          stroke-linejoin: round;
          margin: 0;
          padding: 0;
          transform: rotate(90deg);
          transition: transform 0.3s ease-out;
          width: clamp(20px, 2vw, 35px);
        }
        h2 {
          width: 85%;
          pointer-events: none;
          font-size: clamp(1rem, 3vw, 2.3rem);
        }
      }
      .answer {
        max-height: 1px;
        background: $primary-color;
        transition: all 0.3s;
        overflow: hidden;
        p {
          padding: clamp(1rem, 2vw, 2rem) clamp(1rem, 3vw, 3rem);
          color: $tertiary-color;
          line-height: 1.8;
          letter-spacing: 0.05rem;
          font-size: clamp(0.8rem, 1vw, 1.2rem);
          & + p {
            padding-top: 0;
          }
        }
      }
    }
  }
  .about-call-to-action {
    position: relative;
    height: 100vh;
    width: 100%;
    .container {
      position: static;
      .content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media (max-width: $tablet-breakpoint) {
          width: 90%;
        }
        .text-container {
          h2 {
            line-height: 1;
            font-weight: 600;
            font-size: clamp(3rem, 8vw, 8rem);
            margin-bottom: clamp(1rem, 3vw, 3rem);
            text-transform: initial;
          }
          p {
            margin-bottom: 1rem;
            font-size: 1.4rem;
            color: rgb(125, 125, 125);
            line-height: 1.5;
          }
        }
        button {
          background: white;
          border: 2px solid $primary-color;
          background: none;
          color: $primary-color;
          white-space: nowrap;
          transition: all 0.3s;
          width: clamp(100px, 35vw, 200px);
          padding: clamp(0.7rem, 1vw, 1rem) clamp(1rem, 2vw, 1.5rem);
          margin: clamp(1.5rem, 5vw, 5rem);
          font-size: clamp(1.2rem, 2vw, 1.5rem);
          &:hover {
            background: $primary-color;
            color: $tertiary-color;
          }
        }
      }
    }
  }
  .info-step {
    position: relative;
    width: 100%;
    .step {
      position: relative;
      margin: 10rem 0;
      h2 {
        font-size: clamp(1rem, 17vw, 8rem);
        &:first-of-type {
          position: absolute;
          transform: translate(-30%, -40%);
          font-size: clamp(1rem, 35vw, 11rem);
          color: rgb(221, 221, 221);
          z-index: -1;
        }
      }
      p {
        font-family: $secondary-font;
        line-height: 1.7;
        letter-spacing: 0.05rem;
        color: rgb(130, 130, 130);
        font-size: 1.2rem;
      }
    }
  }
  .info-page-transition {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30vh;
    background: $primary-color;
    z-index: 2;
  }
  .contact {
    position: relative;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .bloop {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
      width: 100%;
      z-index: 2;
      display: grid;
      place-items: center;
      .icon-wrapper {
        position: absolute;
        width: 25%;
        fill: none;
        stroke: white;
        stroke-width: 5px;
        path {
          stroke: $primary-color !important;
          stroke-width: 4px !important;
        }
        @media (max-width: $phone-breakpoint) {
          width: 40%;
        }
      }
    }
    .f-c-status {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 4;
      // visibility: hidden;
      display: flex;
      flex-direction: column;
      width: 100%;
      button {
        background: white;
        border: 2px solid $primary-color;
        background: none;
        color: $primary-color;
        white-space: nowrap;
        transition: all 0.3s;
        width: clamp(100px, 35vw, 200px);
        padding: clamp(0.5em, 1vw, 0.5em) clamp(0.7em, 2vw, 1.2em);
        margin: clamp(1rem, 3vw, 3rem);
        font-size: clamp(1.1rem, 2vw, 1.5rem);
        align-self: center;
        opacity: 1;
        &:hover {
          background: $primary-color;
          color: $tertiary-color;
        }
      }
    }
    form {
      position: relative;
      margin: 5rem auto;
      width: 100%;
      .f-c-logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30%;
        fill: $primary-color;
        // opacity: 0;
        z-index: 2;
        pointer-events: none;

        fill: #f5f5f5;
        fill: red;
        filter: drop-shadow(0px 0px 0px #dadada)
          drop-shadow(-0px -0px 0px #ffffff);
        @media (max-width: $tablet-breakpoint) {
          width: 60%;
        }
      }
      .cover {
        background: rgb(245, 245, 245);
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0%;
        z-index: 1;
        pointer-events: all;
      }
      .f-c-header {
        position: relative;
        margin-bottom: clamp(1.5rem, 3vw, 3rem);
        h2 {
          position: relative;
          font-size: clamp(3.5rem, 7vw, 7rem);
          line-height: 0.9;
          font-weight: 900;
          &:last-of-type::after {
            content: "";
            position: absolute;
            bottom: 40%;
            width: 50%;
            height: 3px;
            margin-left: 2rem;
            background: $secondary-color;
          }
        }
      }
      .row {
        margin: clamp(1.2rem, 2vw, 1.7rem) auto;
        align-items: center;
        justify-content: initial;
        position: relative;
        height: 100%;
        margin-left: clamp(1rem, 7vw, 7rem);
      }
      .expand {
        position: relative;
        margin: 0;
        flex-grow: 1;
        input,
        select,
        .box {
          appearance: none;
          background: rgba(255, 255, 255, 0);
          border: none;
          outline: none;
          border-bottom: 1px solid rgba(121, 121, 121, 1);
          font-size: clamp(1.5rem, 2vw, 2rem);
          color: $secondary-color;
          font-family: $primary-font;
          font-weight: 600;
          width: 100%;
          &::placeholder {
            color: rgb(121, 121, 121);
          }
          option {
            color: $secondary-color;
            &:first-of-type {
              color: rgb(121, 121, 121);
              opacity: 0.5;
            }
          }
          select {
            opacity: 0.5;
            color: rgb(121, 121, 121);
            border: none;
          }
        }
        .box::before {
          content: "\2039";
          transform: rotate(-90deg);
          position: absolute;
          top: 0;
          right: 0;
          width: 10%;
          height: 100%;
          text-align: center;
          font-size: 50px;
          line-height: 45px;
          color: rgba(121, 121, 121, 0.5);
          background-color: rgba(0, 0, 0, 0);
          pointer-events: none;
          @media (max-width: $phone-breakpoint) {
            right: 5%;
          }
        }
        .border {
          display: block;
          position: absolute;
          height: 3px;
          width: 100%;
          top: 98%;
          background: $secondary-color;
          transform: scaleX(0);
          transition: transform 0.5s;
          transform-origin: 0% 50%;
        }
        input:focus + .border,
        .expand:focus .border {
          transform: scaleX(1);
        }
      }
      label {
        font-family: $secondary-font;
        font-family: $primary-font;
        font-weight: 600;
        font-size: clamp(1.5rem, 2vw, 2rem);
      }
      .button-wrapper {
        position: relative;
        button {
          background: white;
          border: 2px solid $primary-color;
          background: none;
          color: $primary-color;
          white-space: nowrap;
          transition: all 0.3s;
          width: clamp(100px, 35vw, 200px);
          padding: clamp(0.35em, 1vw, 0.5em) clamp(0.7em, 2vw, 1.2em);
          margin: clamp(1rem, 3vw, 3rem) 0;
          font-size: clamp(1.2rem, 2vw, 1.5rem);
          &:hover {
            background: $primary-color;
            color: $tertiary-color;
          }
          &:disabled {
            color: $primary-color;
            background: none;
            opacity: 0.3;
          }
        }
      }
    }
  }
}
